<template>
  <div id="app">
    <router-view />
    <notifications position="bottom right" />
    <success-popup v-show="getPopupStatus('success')" />
    <error-popup v-show="getPopupStatus('error')" />
    <start-test v-show="getPopupStatus('startTest')" />
    <process-test v-show="getPopupStatus('processTest')" />
    <Apmcheck v-show="getPopupStatus('apm')"
        :apmApiKey="key"
        :currentPath="'main'"
    />
  </div>
</template>

<script>
import "@/assets/scss/base.scss"
import SuccessPopup from "@/components/popups/SuccessPopup"
import ErrorPopup from "@/components/popups/ErrorPopup"
import { capitalize } from "@/utils/helpers";
import StartTest from "@/components/popups/StartTest"
import ProcessTest from "@/components/popups/ProcessTest"
import Apmcheck from "@/components/Apmcheck"

export default {
  name: 'App',
  components: {
    SuccessPopup,
    ErrorPopup,
    StartTest,
    ProcessTest,
    Apmcheck
  },

  data() {
    return {
      key: "abb82268-7ffb-4227-a220-e198536fe835",
    }
  },

  methods: {
    getPopupStatus(name) {
      return this.$store.state.bus.popups[`is${capitalize(name)}Opened`]
    }
  },

  computed: {
    getStatus() {
      return this.$store.state.bus.isApmOpened
    }
  }
}
</script>

<style>
#apm-scan-qr {
  left: -100%;
}
</style>
