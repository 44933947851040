import { sendFeedback } from "@/api";

export const main = {
	namespaced: true,
	actions: {
		async sendFeedback(context, payload) {
			const response = await sendFeedback(payload.name, payload.email, payload.theme_id, payload.message, payload.file_contents)

			return response.error === 0
		},
	}
}
