import { singIn, singInSoc, singUp, singUpSoc, getProfile, updateProfile, choosePrize } from "@/api";

export const user = {
	namespaced: true,
	state: {
		profile: {
			uuid: "",
			name: "",
			surname: "",
			email: "",
			birthday: new Date(),
			phone: "",
			city: "",
			region: "",
			points: null,
			children: [],
			prizes: []
		},
		globalPrizes: [],
		childVideos: [],
		studentVideos: [],
		token: undefined,
	},
	mutations: {
		SET_TOKEN (state, token) {
			state.token = token
		},

		CLEAR (state) {
			state.profile.uuid = ""
			state.profile.name = ""
			state.profile.surname = ""
			state.profile.email = ""
			state.profile.birthday = new Date()
			state.profile.phone = ""
			state.profile.region = ""
			state.profile.city = ""
			state.profile.points = null
			state.token = undefined
		},

		SET_USER_PROFILE (state, payload) {
			state.profile.uuid = payload.userUuid || state.profile.uuid
			state.profile.name = payload.name || state.profile.name
			state.profile.surname = payload.surname || state.profile.surname
			state.profile.email = payload.email || state.profile.email
			state.profile.birthday = payload.birthday ? new Date(payload.birthday) : state.profile.birthday
			state.profile.phone = payload.phone || state.profile.phone
			state.profile.region = payload.region || state.profile.region
			state.profile.city = payload.city || state.profile.city
			state.profile.points = payload.points ?? state.profile.points
			state.childVideos = payload.childVideos || state.childVideos
			state.studentVideos = payload.studentVideos || state.studentVideos

			if ("children" in payload) {
				state.profile.children = []
				payload.children.forEach((item) => {
					state.profile.children.push({
						name: item.name,
						birthday: new Date(item.age)
					})
				})
			}

			if ("prizes" in payload) {
				state.profile.prizes = []
				payload.prizes.forEach((item) => {
					state.profile.prizes.push(item)
				})
			}

			if ("globalPrizes" in payload) {
				state.globalPrizes = []
				payload.globalPrizes.forEach((item) => {
					state.globalPrizes.push(item)
				})
			}
		},

		REDUCE_POINTS (state, payload) {
			state.profile.points -= payload.points
		}
	},
	actions: {
		async signIn({ commit }, payload) {
			const res = await singIn(payload.login, payload.password)

			if (res.error === 1) {
				return {
					error: res.error,
					message: res.message
				}
			}

			localStorage.setItem("token", res.auth_key)
			commit("SET_TOKEN", res.auth_key)

			const profile = await getProfile()

			commit("SET_USER_PROFILE", profile.result.profile)

			if (profile.result.children.length > 0) {
				const now = new Date()
				const children = profile.result.children.map(item => {
					return {
						name: item.name,
						age: now.setFullYear(now.getFullYear() - item.age)
					}
				})

				commit("SET_USER_PROFILE", { children })
			}

			if (profile.result.prizes.length > 0) {
				commit("SET_USER_PROFILE", { prizes: profile.result.prizes })
			}

			if (profile.result.globalPrizes.length > 0) {
				commit("SET_USER_PROFILE", { globalPrizes: profile.result.globalPrizes })
			}
		},

		async signInSoc({ commit }, payload) {
			const res = await singInSoc(payload.id, payload.type)

			if (res.error === 1) {
				return {
					error: res.error,
					message: res.message
				}
			}

			localStorage.setItem("token", res.auth_key)
			commit("SET_TOKEN", res.auth_key)

			const profile = await getProfile()

			commit("SET_USER_PROFILE", profile.result.profile)

			if (profile.result.children.length > 0) {
				const now = new Date()
				const children = profile.result.children.map(item => {
					return {
						name: item.name,
						age: now.setFullYear(now.getFullYear() - item.age)
					}
				})

				commit("SET_USER_PROFILE", { children })
			}

			if (profile.result.prizes.length > 0) {
				commit("SET_USER_PROFILE", { prizes: profile.result.prizes })
			}

			if (profile.result.globalPrizes.length > 0) {
				commit("SET_USER_PROFILE", { globalPrizes: profile.result.globalPrizes })
			}

			return res
		},

		async signUp(context, payload) {
			const response = await singUp(payload.name, payload.login, payload.phone, payload.birthDate,
				payload.password, payload.passwordConfirm)

			if (response.error === 1) {
				return {
					error: response.error,
					message: response.message
				}
			}

			return response.error === 0
		},

		async signUpSoc(context, payload) {
			const response = await singUpSoc(payload.name, payload.login, payload.phone, payload.birthDate,
				payload.password, payload.passwordConfirm, payload.socId, payload.socType)

			if (response.error === 1) {
				return {
					error: response.error,
					message: response.message
				}
			}

			return response.error === 0
		},

		setToken({ commit }, token) {
			commit("SET_TOKEN", token)
		},

		async setProfile({ commit }) {
			const profile = await getProfile()

			if (profile.error !== 0) {
				localStorage.removeItem("token")
				commit("SET_TOKEN", undefined)
				commit("SET_USER_PROFILE", {
					userUuid: "",
					name: "",
					surname: "",
					email: "",
					birthday: new Date(),
					phone: "",
					region: "",
					points: null,
				})

				return {
					error: 1
				}
			}

			commit("SET_USER_PROFILE", profile.result.profile)

			if (profile.result.children.length > 0) {
				const children = profile.result.children.map(item => {
					return {
						name: item.name,
						age: new Date().setFullYear(new Date().getFullYear() - item.age)
					}
				})

				commit("SET_USER_PROFILE", { children })
			}

			if (profile.result.prizes.length > 0) {
				commit("SET_USER_PROFILE", { prizes: profile.result.prizes })
			}

			if (profile.result.globalPrizes.length > 0) {
				commit("SET_USER_PROFILE", { globalPrizes: profile.result.globalPrizes })
			}

			if ("courses" in profile.result) {
				let childVideos = []
				let studentVideos = []

				childVideos = profile.result.courses.states[0].map(function (item) {
					return {
						id: item.id,
						name: item.name,
						cyrName: item.cyrName,
						start: item.start,
						finished: item.finished,
						addMaterials: item.addMaterials,
						lessons: item.lessons
					}
				})

				studentVideos = profile.result.courses.states[1].map((item) => {
					return {
						id: item.id,
						name: item.name,
						cyrName: item.cyrName,
						start: item.start,
						finished: item.finished,
						addMaterials: item.addMaterials,
						lessons: item.lessons
					}
				})

				commit("SET_USER_PROFILE", { childVideos, studentVideos })
			}
		},

		async updateProfile({ commit }, payload) {
			const profile = await updateProfile(payload.surname, payload.city, payload.children)

			if (profile.error !== 0) {
				commit("SET_USER_PROFILE", {
					surname: "",
					city: "",
					region: "",
					children: []
				})
				return
			}

			commit("SET_USER_PROFILE", payload)
		},

		async clear({ commit }) {
			commit("CLEAR")
		},

		async choosePrize({ commit }, payload) {
			const response = await choosePrize(payload.slug)

			if (response.error === 1) {
				return response
			}

			commit("REDUCE_POINTS", {
				points: payload.points
			})

			const profile = await getProfile()

			commit("SET_USER_PROFILE", profile.result.profile)

			if (profile.result.children.length > 0) {
				const now = new Date()
				const children = profile.result.children.map(item => {
					return {
						name: item.name,
						age: now.setFullYear(now.getFullYear() - item.age)
					}
				})

				commit("SET_USER_PROFILE", { children })
			}

			if (profile.result.prizes.length > 0) {
				commit("SET_USER_PROFILE", { prizes: profile.result.prizes })
			}

			if (profile.result.globalPrizes.length > 0) {
				commit("SET_USER_PROFILE", { globalPrizes: profile.result.globalPrizes })
			}

			return response
		}
	},
	getters: {
		isLoggedIn(state) {
			return state.token !== undefined
		}
	}
}
