<template>
  <div>
    <div class="modal__bg"></div>
    <div class="modal__wrapper">
      <div class="modal__window">
        <div class="modal__content">
          <img @click="close" src="../../../assets/svg/close-icon.svg" alt="Закрыть" class="modal__close-icon">
          <slot></slot>
        </div>
        <div v-if="hasFooter" class="modal__footer">
          <div class="modal__login">Зарегистрироваться через</div>
          <div>
            <img @click="vkLogin" src="../../../assets/svg/icon-ellipse-vk.svg" alt="VK">
            <img src="../../../assets/svg/icon-ellipse-odn.svg" alt="Одноклассники">
            <img src="../../../assets/svg/icon-ellipse-google.svg" alt="Google">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vk from "vk-openapi";

export default {
  props: {
    hasFooter: {
      type: Boolean
    }
  },
  name: "SmallPopup",

  methods: {
    close() {
      this.$emit("close")
    },

    vkLogin() {
      vk.init({
        apiId: 8180215
      })

      vk.Auth.login((data) => {
        if (data.session === null) {
          this.$store.dispatch("bus/closePopup", "register")

          this.$store.dispatch("bus/notifyError", {
            title: "Ошибка",
            message: "Произошла ошибка во время авторизации"
          })

          return
        }

        this.$emit("oauth", data.session.user)
      }, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables";

$background-color: #818181;
$window-color: #FFF;

a {
  text-decoration: none;
  color: $purple;
}

h2 {
  margin: 0;
  padding: 0;
  font-size: 50px;
  text-align: center;
  color: $purple;
}

ol {
  list-style: none;
  counter-reset: item;
  text-align: left;

  li {
    counter-increment: item;
    position: relative;
    margin-bottom: 10px;
    padding-left: 50px;

    &::before {
      position: absolute;
      content: counter(item);
      display: inline-block;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      line-height: 40px;
      background-color: $purple;
      border-radius: 100%;
      color: $white;
      text-align: center;
    }
  }
}

.modal {
  &__bg {
    position: fixed;
    background-color: $background-color;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.9;
    z-index: 998;
  }

  &__wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
  }

  &__window {
    position: relative;
    width: 800px;
    background-color: $window-color;
    border-radius: 40px;
    opacity: 1;
    font-family: "Marvin Round";
    text-transform: uppercase;
  }

  &__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &__close-icon {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  &__footer {
    padding: 40px 0 30px 0;
    background-image: url("../../../assets/svg/modal-footer.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    text-align: center;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;

    img {
      &:nth-child(2) {
        margin: 0 27px 0 27px;
      }
    }
  }

  &__login {
    margin-bottom: 30px;
    font-size: 30px;
    color: $white;
  }
}

@media screen and (max-width: $lg) {
  h2 {
    font-size: 24px;
  }

  .modal {
    &__wrapper {
      top: 10px;
    }

    &__window {
      width: 90%;
    }

    &__footer {
      background-image: url("../../../assets/svg/modal-footer-mob.svg");

      img {
        width: 60px;
        height: 60px;

        &:nth-child(2) {
          margin: 0 18px 0 18px;
        }
      }
    }

    &__login {
      font-size: 16px;
    }
  }
}
</style>