import Vue from "vue"
import axios from "axios"
import Notifications from 'vue-notification'
import store from "@/store";

Vue.use(Notifications)

//const BASE_URL = "https://api.dobry-academia.ru/api"
const BASE_URL = "https://dtest-api.srv08.ru/api"

axios.defaults.headers.common["Accept"] = "application/json"
axios.defaults.headers.common["Content-Type"] = "application/json"

const instance = axios.create({ baseURL: BASE_URL })

instance.interceptors.request.use((config) => {
	config.headers["Accept"] = "application/json";
	config.headers["Content-Type"] = "application/json";

	if (store.getters["user/isLoggedIn"]) {
		config.headers["X-Auth-Token"] = store.state.user.token;
	}

	return config
})

instance.interceptors.response.use((response) => {
	if (response.data.error === 0) {
		return response
	}

	let errors = []

	if (typeof response.data.message === 'object') {
		errors = Object.entries(response.data.message).map(([key, value]) => {
			return `Поле  ${key}, ошибка: ${value}`
		})
	} else {
		errors.push(response.data.message)
	}

	// store.dispatch("bus/notifyError", {
	// 	title: "Ошибка!",
	// 	message: errors.join("<br>")
	// })

	// Vue.notify({
	// 	title: "Ошибка!",
	// 	text: errors.join("<br>"),
	// 	type: "error"
	// })

	return response
})

export default instance
