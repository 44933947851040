import request from "@/utils/request"

export const singIn = async (login, password) => {
	const res = await request.post("/authenticateByEmail", { login, password })

	return res.data
}

export const singInSoc = async (id, type) => {
	const req = {}

	switch (type) {
		case "vk":
			req.identity_vk = id
			break

		case "ok":
			req.identity_ok = id
			break

		case "google":
			req.identity_go = id
			break
	}

	const res = await request.post("/authenticateBySoc", req)

	return res.data
}

export const singUp = async (name, login, phone, birthDate, password, passwordConfirm) => {
	const res = await request.post("/registerByEmail", {
		name,
		login,
		phone,
		birth_date: birthDate,
		pass: password,
		passR: passwordConfirm
	})

	return res.data
}

export const singUpSoc = async (name, login, phone, birthDate, password, passwordConfirm, socId, socType) => {
	const req = {
		name,
		login,
		phone,
		birth_date: birthDate,
		pass: password,
		passR: passwordConfirm
	}

	switch (socType) {
		case "vk":
			req.identity_vk = socId
			break

		case "ok":
			req.identity_ok = socId
			break

		case "google":
			req.identity_go = socId
			break
	}

	const res = await request.post("/registerByEmailSoc", req)

	return res.data
}

export const getProfile = async () => {
	const res = await request.get("/getProfile")

	return res.data
}

export const sendFeedback = async (name, email, theme_id, message, file_contents) => {
	const res = await request.post("/sendFeedback", {
		name,
		email,
		theme_id,
		message,
		file_contents
	})

	return res.data
}

export const updateProfile = async (surname, city, children) => {
	const res = await request.post("/updateProfile", {
		surname,
		city,
		children
	})

	return res.data
}

export const getReceipts = async () => {
	const res = await request.get("/getCodes")

	return res.data
}

export const resendPassword = async (email) => {
	const res = await request.post("/resendPassword", { email })

	return res.data
}

export const viewLesson = async (courseId, lessonId) => {
	const res = await request.post("/viewLesson", {
		course_id: courseId,
		lesson_id: lessonId,
	})

	return res.data
}

export const passQuiz = async (courseId, lessonId) => {
	const res = await request.post("/passQuiz", {
		course_id: courseId,
		lesson_id: lessonId,
	})

	return res.data
}

export const choosePrize = async (prizeSlug) => {
	const res = await request.post("/getGarantPrize", {
		slug: prizeSlug
	})

	return res.data
}

export const getWinners = async (prize, phone, date) => {
	const res = await request.get("/getNewWinners", {
		params: {
			prize_name: prize,
			phone,
			created_at: date
		}
	})

	return res.data
}
