import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Notifications from "vue-notification"
import VueCountdown from "@chenfengyuan/vue-countdown"
import VueTheMask from "vue-the-mask"

Vue.component(VueCountdown.name, VueCountdown);

Vue.config.productionTip = false

Vue.use(Notifications)
Vue.use(VueTheMask)

router.beforeEach(async (to, from, next) => {
  if (store.getters["user/isLoggedIn"]) {
    next()

    return
  }

  const token = localStorage.getItem("token")

  if (token !== null) {
    await store.dispatch("user/setToken", token)
    await store.dispatch("user/setProfile")
    next()

    return
  }

  if (to.path.slice(1).split("/").includes("personal")) {
    next("/") // redirect to the main page if the user is not logged in

    return
  }

  next()
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
