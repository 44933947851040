import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const baseTitle = 'Добрый 2022';

const routes = [
	{
		path: '/',
		name: 'Main',
		component: () => import('@/views/Main.vue'),
		meta: {
			title: baseTitle + ' - Академия Супер',
		}
	},
	{
		path: '/prizes',
		name: 'Prizes',
		component: () => import('@/views/Prizes.vue'),
		meta: {
			title: baseTitle + ' - Призы',
		}
	},
	{
		path: '/product',
		name: 'Product',
		component: () => import('@/views/Product.vue'),
		meta: {
			title: baseTitle + ' - Продукт',
		}
	},
	{
		path: '/faq',
		name: 'FAQ',
		component: () => import('@/views/FAQ.vue'),
		meta: {
			title: baseTitle + ' - FAQ',
		}
	},
	{
		path: '/about',
		name: 'About',
		component: () => import('@/views/About.vue'),
		meta: {
			title: baseTitle + ' - Об акции',
		}
	},
	{
		path: '/academy',
		name: 'Академия',
		component: () => import('@/views/Academy.vue'),
		meta: {
			title: baseTitle + ' - Академия',
		}
	},
	{
		path: '/personal/profile',
		name: 'ProfilePersonal',
		component: () => import('@/views/personal/ProfilePersonal.vue'),
		meta: {
			title: baseTitle + ' - Профиль',
		}
	},
	{
		path: '/personal/prizes',
		name: 'PrizesPersonal',
		component: () => import('@/views/personal/PrizesPersonal.vue'),
		meta: {
			title: baseTitle + ' - Профиль призы',
		}
	},
	{
		path: '/personal/history',
		name: 'HistoryPersonal',
		component: () => import('@/views/personal/HistoryPersonal.vue'),
		meta: {
			title: baseTitle + ' - Профиль история',
		}
	},
	{
		path: '/winners',
		name: 'GarantWinners',
		component: () => import('@/views/winners/GarantWinners.vue'),
		meta: {
			title: baseTitle + ' - Победители',
		}
	},
	{
		path: '/oauth/:issuer/callback',
		name: 'OAuth',
		component: () => import('@/components/OAuth'),
		meta: {
			title: baseTitle + ' - OAuth',
		}
	},
]

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})
