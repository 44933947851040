import Vue from "vue"
import Vuex from "vuex"
import { bus } from "./modules/bus"
import { user } from "./modules/user"
import { main } from "./modules/main"

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		bus,
		user,
		main
	}
})
