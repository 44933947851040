<template>
  <modal @close="close">
    <div class="info__wrapper">
      <div class="count">
        вопрос {{ curIndex + 1 }}/{{ questions.length }}
      </div>
      <h2 style="margin-bottom: 25px">{{ questions[curIndex].text }}</h2>
        <div class="item" v-for="(item, idx) in questions[curIndex].options" :key="item">
          <img class="answer-item" :id="'answer'+(idx+1)" @click="chooseAnswer(idx + 1)" :src="getPng(item.img)" alt="">
          <img class="answer-logo" src="../../assets/img/answer.png" alt="">
          <p class="info__text">{{ item.text }}</p>
        </div>
      <div class="info__button-group">
        <button class="info__button info__button-main" @click="answer">
          Ответить
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import SmallPopup from "@/components/popups/layouts/SmallPopup"
import {passQuiz} from "@/api"

export default {
  name: "ProcessTest",
  components: {
    Modal: SmallPopup
  },

  data() {
    return {
      questions: [],
      isAnswerCorrect: true,
      curIndex: 0,
      selectedAnswer: 0
    }
  },

  methods: {
    close() {
      this.$store.dispatch("bus/closePopup", "processTest")
    },

    getPng(name) {
      return require(`../../assets/quiz/${name}`)
    },

    chooseAnswer(number) {
      this.selectedAnswer = number
      const frame2 = document.querySelectorAll('.answer-item')

      frame2.forEach(function (item) {
        item.classList.remove("active");
      })
      const frame = document.querySelectorAll("#answer"+number)

      frame.forEach(function (item) {
        item.classList.add("active");
      })
    },

    answer() {
      if (this.selectedAnswer === 0) {
        return
      }

      if (this.questions[this.curIndex].correctAnswer !== this.selectedAnswer) {
        this.isAnswerCorrect = false
      }

      this.selectedAnswer = 0

      if (this.questions.length === this.curIndex + 1) {
        console.log("done")


        if (this.isAnswerCorrect) {
          this.$store.dispatch("bus/notifySuccess", {
            title: "Успешно",
            message: "Вы сдали тест"
          })
          passQuiz(this.$store.state.bus.curCourse, this.$store.state.bus.curLesson)
        } else {
          this.$store.dispatch("bus/notifyError", {
            title: "Неудача",
            message: "На один из вариантов вы ответили некорректно"
          })
        }

        this.curIndex = 0
        this.isAnswerCorrect = true
      } else {
        console.log("undone")
        this.curIndex = this.curIndex + 1
      }
    }
  },

  computed: {
    getTest() {
      return this.$store.state.bus.popups.test
    }
  },

  watch: {
    getTest() {
      this.questions = this.getTest
    }
  }
}
</script>

<style scoped>

</style>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

h2 {
  font-size: 20px;
}

.info {
  &__wrapper {
    row-gap: 30px;
    padding: 55px 0 70px 0;
    text-align: center;
  }

  &__text {
    font-size: 16px;
  }

  &__button {
    display: inline-block;
    width: 336px;
    height: 80px;
    font-family: "Marvin Round";
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    border-radius: 50px;
    cursor: pointer;

    &:first-child {
      margin-right: 20px;
    }
  }

  &__button-main {
    background-color: $purple;
    color: $white;

    &:hover {
      background-color: $purple;
    }
  }

  &__button-secondary {
    background-color: $white;
    color: $purple;
    border: 3px solid $purple;
  }
}

.item {
  display: inline-block;
  margin: 0 20px;
  font-size: 16px;
  position: relative;
  max-width: 28%;
  vertical-align: top;

  img {
    width: 150px;
    border-radius: 15px;
    border: 3px solid transparent;
  }

  img.active {
    border: 3px solid #3F3F3F;
  }

  .answer-logo {
    display: none;
  }

  img.active + .answer-logo {
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
  }
}

.count {
  background-color: #3F3F3F;
  color: #3F3F3F;
  padding: 10px 40px;
  display: inline-block;
  font-size: 21px;
  margin-bottom: 15px;
  border-radius: 25px;
}

@media screen and (max-width: 992px) {
  .item {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}

</style>