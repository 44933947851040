<template>
  <div>
    <div v-show="! getLoginCheck" @click="showLoginPopup"
         class="landing-button register-receipt">
      Зарегистрировать чек
    </div>
    <div v-show="getLoginCheck" id="apm-widget"></div>
  </div>
</template>

<script>
export default {
  name: "Apmcheck",
  props: ["apmApiKey", "pageId", "currentPath"],
  data() {
    return {
      ready: false,
      currentKey: this.apmApiKey,
    };
  },

  methods: {
    showLoginPopup() {
      this.$store.dispatch("bus/change", {
        name: 'wantsToDownloadReceipt',
        value: true
      })
      this.$store.dispatch("bus/openPopup", "login")
    },


  },

  computed: {
    getLoginCheck() {
      return this.$store.getters['user/isLoggedIn']
    }
  },

  mounted() {
    this.ready = true;
    const t = this;

    const widgetParams = {
      api: "https://api.apmcheck.ru",
      apiKey: this.currentKey,
      // userUuid: this.$store.getters.user.profile.userUuid + this.pageId,
      userUuid: this.$store.state.user.profile.uuid,
      callbacks: {
        onReceiptSentSuccess: function (data) {
          console.log(data)
          // t.$root.$emit("onCheckSended");
          // t.gtmEvent(t.currentPath, "success", "check_upload");
        },
      },
      i18n: {
        labels: {
          mainButton: "Сканировать QR-код",
          manualQrButton: "Ввести вручную",
          uploadPhotosButton: "загрузить фото чека",
          submitManualQrButton: "Отправить",
          addPhotoButton: "загрузить фотографию чека",
          submitPhotosButton: "Отправить",
        },
        screens: {
          scanQr: {
            header: "загрузка чека",
            subheader: "Изображения чека должно быть загружено полностью!",
          },
          cameraNotAvailable: {
            subheader:
              '<span class="apm-title">загрузка чека</span><span class="amp-error">Вы можете разрешить браузеру обращаться к камере, ввести данные чека вручную или загрузить фотографию чека.</span>',
          },
          qrParsed: {
            header: "Спасибо! твой чек зарегистрирован",
            subheader: "после того, как твой чек пройдет модерацию, начисленные тебе баллы отразятся в личном кабинете",
          },
          uploadFiles: {
            header: "Загрузка фото",
            subheader: "Добавь фото чека (до 5 частей)",
            fileTooLargeError:
              "Файл больше 10 МБ. Загрузите файл меньшего размера",
          },
          sentReceiptFailed: {
            header: "Не удалось отправить чек!",
          },
        },
      },
    };
    window.qrWidget.init("apm-widget", widgetParams);
  },

  components: {},
  watch: {
    apmApiKey: function (newVal, oldVal) {
      this.currentKey = newVal;
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/variables";
@import "../assets/scss/functions";

#apm-container {
  height: 80px !important;
  border-radius: 56px !important;
}

.apm-close {
  margin: 0 !important;
  top: 20px !important;
  right: 25px !important;
  background-color: $white !important;
  border-radius: 0 !important;
  min-height: 28px !important;
  max-height: 28px !important;
  min-width: 28px !important;
  max-width: 28px !important;

  &::before {
    position: absolute !important;
    content: "" !important;
    display: block !important;
    width: 28px !important;
    height: 28px !important;
    top: 0 !important;
    left: 0 !important;
    background-image: url("../assets/svg/close-icon.svg") !important;
    background-size: cover !important;
    transform: none !important;
    cursor: pointer !important;
  }

  &::after {
    display: none !important;
  }
}

.apm-qr-btn-text {
  display: none !important;
}

.apm-btn {
  width: auto !important;
}

.apm-block-btn {
  width: auto !important;
  height: 80px !important;
  color: #fff !important;
  padding: 0 20px 0 20px !important;
  background-color: $yellow !important;
  box-shadow: none !important;
}

.apm-btn-main {
  box-shadow: none !important;
}

.apm-action-button::after {
  content: "загрузка чека" !important;
  display: block !important;
  height: 80px !important;
  line-height: 80px !important;
  font-size: 18px !important;
  color: #fff !important;
}

.apm-action-button::before {
  content: "" !important;
  position: absolute !important;
  //width: 40px !important;
  //height: 44px !important;
  top: 19px !important;
  left: 17px !important;
  //background-image: url("../assets/svg/receipt.svg") !important;
}

.apm-modal-dialog {
  max-width: 920px !important;
}

.apm-modal-content {
  padding-bottom: 50px !important;
  border-radius: 40px !important;
  border: none !important;
}

.apm-qr-video {
  display: none !important;
}

.apm-title-manual {
  color: $white !important;

  &::before {
    content: "" !important;
    display: block !important;
    width: 100% !important;
    height: 140px !important;
    //background-image: url("../assets/svg/icon-receipt.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }
}

.apm-p {
  font-family: "Marvin Round" !important;
  font-size: 24px !important;
  color: $black !important;
}

.apm-btn-manual-input {
  width: 520px !important;
  height: 80px !important;
  line-height: 80px !important;
  background-color: $purple !important;
  border-radius: 60px !important;
}

.btn-content {
  font-family: "Marvin Round" !important;
  font-size: 24px !important;
  color: $white !important;
}

.apm-title {
  margin-bottom: 30px !important;
  width: 100% !important;
  margin-top: 30px !important;
  font-family: "Marvin Round" !important;
  font-size: 26px !important;
  color: $purple !important;
  display: block !important;
}

.apm-action-button-dupe {
  display: none !important;
}

.apm-block-qr {
  font-family: "Marvin Round" !important;
  text-align: center !important;
  color: $black !important;
}

.apm-qr-input {
  display: inline-block !important;
  font-family: "Montserrat" !important;
  border-radius: 30px !important;
  text-align: center !important;
  color: $black !important;
}

@media screen and (max-width: $lg) {
  #apm-modal-content {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .apm-modal-dialog {
    margin: 0 !important;
  }

  .apm-modal-content {
    width: auto !important;
  }

  .apm-title-manual::before {
    height: 100px !important;
    background-size: contain !important;
  }

  .apm-title-manual::after {
    font-size: 24px !important;
  }

  .apm-p {
    font-size: 16px !important;
  }

  .apm-btn-manual-input {
    width: auto !important;
    height: 50px !important;
    line-height: 50px !important;
  }

  .btn-content {
    font-size: 16px !important;
  }

  #apm-modal-dialog {
    margin-top: 50px !important;
  }
}

//.apm-modal-backdrop {
//  background: rgba(0, 0, 0, 0.8) !important;
//}
//#apm-widget {
//  left: -100%;
//  position: absolute;
//}
//#apm-modal {
//  .apm-qr-video {
//    width: 50%;
//    margin: rem(30px) auto;
//    border: rem(5px) dashed $yellow;
//    //background: url("../assets/images/camera.svg") no-repeat center;
//    background-size: 20%;
//    border-radius: rem(20px);
//  }
//  .apm-modal-header {
//    padding: 0;
//    position: absolute;
//    top: rem(10px);
//    right: rem(5px);
//  }
//  .apm-modal-content {
//    position: relative;
//    border: 0;
//    padding: rem(65px) rem(25px) rem(65px);
//    background: #fff;
//    border-radius: rem(30px);
//  }
//  .apm-modal-body {
//    max-width: 100%;
//    margin: auto;
//    padding: 0;
//  }
//  .apm-modal-dialog {
//    max-width: rem(1026px);
//  }
//  .apm-title {
//    display: block;
//    margin-bottom: rem(30px);
//    color: $yellow;
//    font-family: "Marvin";
//    text-transform: uppercase;
//    font-size: rem(50px);
//    line-height: 1.2;
//    text-align: center;
//  }
//
//  .amp-error {
//    display: block;
//    margin: auto;
//    padding: rem(25px) rem(40px) rem(40px);
//    color: $green;
//    font-size: rem(28px);
//    line-height: rem(38px);
//    &::before {
//      content: "";
//      display: block;
//      width: rem(130px);
//      height: rem(130px);
//      margin: 0 auto rem(26px);
//      //background: url("../assets/images/error.svg") no-repeat center;
//      background-size: contain;
//    }
//  }
//  .apm-p-input {
//    color: $green;
//    font-family: "Marvin";
//    font-size: rem(18px);
//  }
//  #apm-submit-qr {
//    margin-top: 36px;
//  }
//  .apm-block-qr {
//    text-align: center;
//  }
//  .apm-qr-input {
//    outline: none !important;
//  }
//  .apm-validation-error {
//    text-align: center;
//  }
//  #apm-upload-button {
//    display: flex;
//    align-items: center;
//    height: 180px;
//    background: #fff;
//    border: rem(6px) dashed $yellow;
//    box-sizing: border-box;
//    border-radius: rem(20px);
//    .btn-content {
//      font-weight: bold;
//      font-size: rem(18px);
//      line-height: rem(25px);
//      text-align: center;
//      text-transform: uppercase;
//      font-family: "Marvin";
//      color: $green !important;
//      &:before {
//        content: "";
//        display: block;
//        width: rem(64px);
//        height: rem(48px);
//        margin: 0 auto rem(19px);
//        //background: url("../assets/images/upload.svg") no-repeat center;
//        background-size: contain;
//      }
//    }
//  }
//  .apm-close {
//    cursor: pointer;
//    position: absolute;
//    top: rem(37px);
//    right: rem(37px);
//    width: rem(25px);
//    height: rem(25px);
//    max-width: none;
//    max-height: none;
//    background-color: transparent !important;
//    //background-image: url("../assets/images/close_icon.svg");
//    background-repeat: no-repeat;
//    background-size: rem(25px);
//    background-position: center;
//    border-radius: 0px;
//    transition: 0.4s;
//
//    &:before,
//    &:after {
//      display: none;
//    }
//  }
//
//  .apm-photo-thumbnail-image {
//    object-fit: contain;
//    border-radius: 8px;
//  }
//
//  .apm-photo-thumbnail-wrapper {
//    border: rem(3px) solid $pink;
//    border-radius: rem(10px);
//    &:first-of-type {
//      margin-top: rem(40px);
//    }
//  }
//
//  .apm-remove-photo-button {
//    background-color: $yellow;
//  }
//  .apm-block-btn {
//    width: 100%;
//  }
//  .apm-btn,
//  .apm-action-button {
//    min-width: rem(376px);
//    height: rem(85px);
//    line-height: rem(85px);
//    max-width: 100%;
//    cursor: pointer;
//    text-align: center;
//    font-size: rem(24px);
//    padding: 0 rem(25px) 0;
//    transition: all 0.3s ease-in-out;
//    background: $green;
//    border-radius: rem(50px);
//    box-shadow: none;
//    box-sizing: border-box;
//
//    .btn-content,
//    .apm-qr-btn-text {
//      font-family: "Roboto";
//      font-size: rem(24px);
//      font-weight: 700;
//      letter-spacing: normal;
//      text-transform: uppercase;
//      color: #fff;
//      transition: all 0.3s ease-in-out;
//    }
//
//    &:hover {
//      background-color: #18462a;
//    }
//  }
//
//  .apm-block-qr-video {
//    margin-bottom: 2.5rem;
//  }
//  .apm-qr-input {
//    width: rem(428px);
//    max-width: 100%;
//    margin: rem(10px) auto;
//    height: rem(85px);
//    appearance: none;
//    font-family: "Roboto";
//    font-size: rem(28px);
//    background-color: #f2f2f2;
//    border: rem(3px) solid #f2f2f2;
//    border-radius: rem(50px);
//    /*text-transform: uppercase;*/
//    padding: 0 rem(37px) 0;
//    transition: all 0.3s ease-in-out;
//    color: #555555;
//    &::placeholder {
//      color: #555555;
//    }
//    &:-webkit-autofill::first-line {
//      font-size: rem(28px);
//    }
//    &:not(:placeholder-shown) {
//      border-color: $green;
//    }
//    &:focus {
//      outline: none;
//      border-color: $yellow;
//      box-shadow: 0px 4px 14px rgba(255, 172, 0, 0.2);
//    }
//    &[type="datetime-local"] {
//      //background-image: url("../assets/images/calendar.svg");
//      background-repeat: no-repeat;
//      background-position: center right rem(23px);
//      cursor: pointer;
//      &:not(:placeholder-shown) {
//        border-color: #fff;
//      }
//      .hasvalue & {
//        border-color: $green;
//      }
//      &:focus {
//        outline: none;
//        border-color: $yellow;
//        box-shadow: 0px 4px 14px rgba(255, 172, 0, 0.2);
//      }
//    }
//    &::-webkit-inner-spin-button,
//    &::-webkit-calendar-picker-indicator {
//      opacity: 0;
//      width: 30%;
//      height: 50%;
//      -webkit-appearance: none;
//      cursor: pointer;
//    }
//  }
//  .apm-submit-qr {
//    .apm-block-btn {
//      margin: auto;
//    }
//  }
//
//  .apm-p {
//    color: $green;
//    font-family: "Marvin";
//    font-size: rem(30px);
//    a._red {
//      display: inline-block;
//      margin-top: rem(14px);
//    }
//  }
//  .apm-img-error {
//    display: none;
//  }
//  @media (max-width: $sm) {
//    .apm-modal-content {
//      padding-top: rem(80px);
//    }
//    .apm-title {
//      margin-bottom: rem(20px);
//      font-size: rem(30px);
//    }
//    .apm-p {
//      font-size: rem(16px);
//      line-height: rem(20px);
//    }
//    .apm-qr-video {
//      width: 80%;
//    }
//    .amp-error {
//      margin-bottom: rem(20px);
//      padding: 0;
//      font-size: rem(16px);
//      line-height: rem(20px);
//      &::before {
//        width: rem(80px);
//        height: rem(80px);
//      }
//    }
//    .apm-btn,
//    .apm-action-button {
//      min-width: rem(320px);
//      max-width: 100%;
//      height: rem(50px);
//      line-height: rem(45px);
//      .btn-content,
//      .apm-qr-btn-text {
//        font-size: rem(14px);
//        line-height: rem(50px);
//      }
//    }
//    .apm-p-input {
//      font-size: rem(16px);
//    }
//    .apm-block-qr {
//      padding-top: rem(20px);
//    }
//    .apm-qr-input {
//      margin-bottom: 0;
//      height: rem(50px);
//      font-size: rem(16px);
//    }
//  }
//  @media (min-width: $sm) {
//    .apm-block-btn,
//    .apm-btn {
//      width: rem(256px);
//    }
//  }
//  @media (min-width: $md) {
//    .apm-modal-content {
//      padding: rem(78px) rem(43px) rem(70px);
//    }
//  }
//}
.apm-img-error-block {
  display: none !important;
}
</style>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.landing-button {
  padding: 0 20px 0 20px;
  box-sizing: border-box;
  font-weight: 400;
  text-align: center;
  border-radius: 56px;
  white-space: nowrap;
  cursor: pointer;
}

.register-receipt {
  position: relative;
  height: 80px;
  font-size: 18px;
  line-height: 80px;
  color: #fff;
  background-color: #3F3F3F;

  &::before {
    content: "";
    position: absolute;
    //width: 40px;
    //height: 44px;
    top: 19px;
    left: 17px;
//    background-image: url("../assets/svg/receipt.svg");
  }
}

@media screen and (max-width: 992px) {
  .landing-button {
    display: block;
    height: 50px;
    margin: 10px 0 10px 0;
    line-height: 50px;
    font-size: 16px;
  }

  .register-receipt::before {
    top: 4px;
  }
}
</style>
