import { capitalize } from "@/utils/helpers"

export const bus = {
	namespaced: true,
	state: {
		popups: {
			isLoginOpened: false,
			isRegisterOpened: false,
			isForgotOpened: false,
			isReceiptOpened: false,
			isSuccessOpened: false,
			isErrorOpened: false,
			isStartTestOpened: false,
			isProcessTestOpened: false,
			isApmOpened: false,
			isInviteOpened: false,
			isPrizeOpened: false,
			success: {
				title: "",
				message: ""
			},
			error: {
				title: "",
				message: ""
			},
			test: []
		},
		oauth: {
			name: "",
			email: "",
			phone: "",
			soc: {},
			birthDate:  null
		},
		toggler: false,
		wantsToDownloadReceipt: false,
		curCourse: null,
		curLesson: null
	},
	mutations: {
		CHANGE_PROPERTIES_TO_FALSE (state) {
			state.popups.isLoginOpened = false
			state.popups.isRegisterOpened = false
			state.popups.isReceiptOpened = false
			state.popups.isSuccessOpened = false
			state.popups.isErrorOpened = false
			state.popups.isStartTestOpened = false
			state.popups.isProcessTestOpened = false
		},

		CHANGE_PROPERTY (state, property) {
			state[property.name] = property.value
		},

		CHANGE_POPUP_STATUS (state, payload) {
			state.popups[payload.name] = payload.status
		},

		FILL_POPUP_DATA (state, payload) {
			state.popups[payload.name].title = payload.title
			state.popups[payload.name].message = payload.message
		},

		FILL_TEST_DATA (state, payload) {
			state.popups.test = payload
		},

		FILL_OAUTH_DATA (state, payload) {
			state.oauth.name = payload.name
			state.oauth.email = payload.email
			state.oauth.phone = payload.phone
			state.oauth.soc = payload.soc
			state.oauth.birthDate = payload.birthDate
		}
	},
	actions: {
		fillOAuth({ commit }, payload) {
			commit("FILL_OAUTH_DATA", payload)
		},

		fillTest({ commit }, property) {
			commit("FILL_TEST_DATA", property)
		},

		change({ commit }, property) {
			commit('CHANGE_PROPERTY', property)
		},

		closePopup({ commit }, popup) {
			commit('CHANGE_POPUP_STATUS', {
				name: `is${capitalize(popup)}Opened`,
				status: false
			})
		},

		openPopup({ commit }, popup) {
			commit('CHANGE_PROPERTIES_TO_FALSE')
			commit('CHANGE_POPUP_STATUS', {
				name: `is${capitalize(popup)}Opened`,
				status: true
			})
		},

		notifySuccess({ commit }, popup) {
			commit('FILL_POPUP_DATA', Object.assign({ name: "success" }, popup))
			commit('CHANGE_PROPERTIES_TO_FALSE')
			commit('CHANGE_POPUP_STATUS', {
				name: "isSuccessOpened",
				status: true
			})
		},

		notifyError({ commit }, popup) {
			commit('FILL_POPUP_DATA', Object.assign({ name: "error" }, popup))
			commit('CHANGE_PROPERTIES_TO_FALSE')
			commit('CHANGE_POPUP_STATUS', {
				name: "isErrorOpened",
				status: true
			})
		}
	},
	getters: {}
}
